<template>
  <y_layout>
    <div v-if="['chain','area-agent','knowledge'].includes(pathModeName)" slot="left" style="width: 6rem" class="flex-def">
      <el-menu unique-opened v-if="pathModeName === 'chain'" router :default-active="$route.path" style="height: 100%;">
        <el-menu-item index="/addons/chain/upgrade" :route="{name:'addonsChainUpgrade'}">加盟专区</el-menu-item>
        <el-menu-item index="/addons/chain/free" :route="{name:'addonsChainFree'}">免费专区</el-menu-item>
        <el-menu-item index="/addons/chain/set" :route="{name:'addonsChainSet'}">规则配置</el-menu-item>
        <el-menu-item index="/addons/chain/member" :route="{name:'addonsChainMember'}">会员</el-menu-item>
        <el-menu-item index="/addons/chain/agent" :route="{name:'addonsChainAgent'}">代理</el-menu-item>
        <el-menu-item index="/addons/chain/boos" :route="{name:'addonsChainBoos'}">老板</el-menu-item>
        <el-menu-item index="/addons/chain/chief" :route="{name:'addonsChainChief'}">总监</el-menu-item>
      </el-menu>
      <el-menu unique-opened v-if="pathModeName === 'knowledge'" router :default-active="path" style="height: 100%;">
        <el-menu-item index="/addons/knowledge/course" :route="{name:'addonsKnowledgeCourse'}">课程管理</el-menu-item>
        <el-menu-item index="/addons/knowledge/cate" :route="{name:'addonsKnowledgeCate'}">课程分类</el-menu-item>
        <el-menu-item index="/addons/knowledge/teacher" :route="{name:'addonsKnowledgeTeacher'}">讲师管理</el-menu-item>
        <el-menu-item index="/addons/knowledge/conf" :route="{name:'addonsKnowledgeConf'}">基础配置</el-menu-item>
      </el-menu>
      <el-menu v-if="pathModeName === 'area-agent'" router :default-active="$route.path" style="height: 100%">
        <el-submenu index="2-1" style="width: 100%">
          <template slot="title">商品专区</template>
          <el-menu-item index="/addons/area-agent/up-agent" :route="{name:'addonsAreaAgentUpAgent'}">会员</el-menu-item>
          <el-menu-item index="/addons/area-agent/up-street" :route="{name:'addonsAreaAgentUpStreet'}">乡镇代理</el-menu-item>
          <el-menu-item index="/addons/area-agent/up-district" :route="{name:'addonsAreaAgentUpDistrict'}">区县代理</el-menu-item>
          <el-menu-item index="/addons/area-agent/up-city" :route="{name:'addonsAreaAgentUpCity'}">市级代理</el-menu-item>
          <el-menu-item index="/addons/area-agent/up-province" :route="{name:'addonsAreaAgentUpProvince'}">省级代理</el-menu-item>
        </el-submenu>
        <el-submenu index="2-2" style="width: 100%">
          <template slot="title">会员管理</template>
          <el-menu-item index="/addons/area-agent/u-agent" :route="{name:'addonsAreaAgentUAgent'}">会员</el-menu-item>
          <el-menu-item index="/addons/area-agent/u-street" :route="{name:'addonsAreaAgentUStreet'}">乡镇代理</el-menu-item>
          <el-menu-item index="/addons/area-agent/u-district" :route="{name:'addonsAreaAgentUDistrict'}">区县代理</el-menu-item>
          <el-menu-item index="/addons/area-agent/u-city" :route="{name:'addonsAreaAgentUCity'}">市级代理</el-menu-item>
          <el-menu-item index="/addons/area-agent/u-province" :route="{name:'addonsAreaAgentUProvince'}">省级代理</el-menu-item>
          <el-menu-item index="/addons/area-agent/partner" :route="{name:'addonsAreaAgentPartner'}">合伙人</el-menu-item>
        </el-submenu>
        <el-menu-item index="/addons/area-agent/set" :route="{name:'addonsAreaAgent'}">规则配置</el-menu-item>
      </el-menu>
    </div>
    <router-view/>
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";

export default {
  name: "index",
  components: {y_layout},
  computed: {
    pathModeName(){
      return (this.$route.path.split("/"))?.[2]
    }
  },
  data(){
    return{
      path: "",
    }
  },
  methods:{
    onRouteChanged() {
      let that = this;
      if (this.pathModeName === 'knowledge'){
        that.path = this.$route.matched[2].path;
      }
    },
  },
  created() {
    this.onRouteChanged()
  },
  watch: {
    '$route': 'onRouteChanged'
  }
}
</script>

<style>
.el-submenu__icon-arrow{
  right: 8px !important;
}
.el-submenu .el-menu-item{
  min-width: unset;
  width: 6rem !important;
  padding: 0 20px !important;
  margin-left: 10px !important;
}
</style>